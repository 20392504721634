import React, { useState, useEffect } from "react"
import FAQ from "./Faq"
import Image from 'next/future/image'
import ThreeInOne from './ThreeInOne'
import TestimonialSlider from './TestimonialSlider'
import StorySection from './StorySection'

const MarketingContent = ({ style, roomName, showHeaderSection, setShowHeaderSection, isStartpage }) => {
  const [showBefore, setShowBefore] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768) // Adjust this breakpoint as needed
    }

    checkMobile()
    window.addEventListener("resize", checkMobile)

    return () => window.removeEventListener("resize", checkMobile)
  }, [])

  return (
    <>
      <section
        className={`
          overflow-hidden 
          mb-20 
          ${showHeaderSection ? "" : "hidden"}
        `}
      >
        <div className="relative z-10 container mx-auto px-4">
          <div className="flex flex-wrap justify-center items-center">
            <div className="w-auto ">
            
              <div className="flex justify-center pt-6">
                {/* Image Section */}
                <div className="w-full relative mx-auto lg:w-4/6">
                  {/* After Image (Larger Image) */}
                  <div className="absolute flex items-center space-x-1 z-20 right-4 sm:top-6 sm:left-12 top-2">
                              <span className="text-pink-700">★</span>
                              <span className="text-pink-700">★</span>
                              <span className="text-pink-700">★</span>
                              <span className="text-pink-700">★</span>
                              <span className="text-pink-700">★</span>
                              <span className="text-gray-900 font-heading text-xs sm:text-sm">Nr1. Favorite on Reddit</span>
                            </div>
                  <div className="relative z-10 w-full mx-auto">
                    <a href="/interior-design-ai/b/studio-mcgee-living-room-modern-elegance-white-beige-grey-5f3a">
                      <Image
                        src="https://storage.googleapis.com/moodly-16ff8.appspot.com/ai-generator/upscaled_images/vt77xt2rg5rj60cjj3gtxq686m.jpg"
                        alt="After Image"
                        className={`w-full h-auto transform ease-out hover:translate-y-3 duration-1000 transition rounded-2xl shadow-2xl   ${
                          showBefore ? "opacity-0" : "opacity-90"
                        }`}
                        width={1824}
                        height={1212}
                      />
                      <div className="absolute inset-0 bg-gradient-to-b from-white via-transparent to-transparent  pointer-events-none rounded-2xl"></div>
                     
                      {/* Hidden Before Image */}
                      <div
                        className={`absolute top-0 left-0 w-full h-full z-20 transition-opacity duration-300 ${
                          showBefore ? "opacity-100" : "opacity-0"
                        }`}
                      >
                        <Image
                          src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/depositphotos-40115413-stock-photo-charming-tropical-living-room-1-transformed.jpeg"
                          alt="Before Image"
                          className="w-full h-auto object-cover rounded-2xl"
                          // layout="responsive"
                          width={1824}
                           height={1212}
                        />
                        <div className="absolute left-2 bg-opacity-70 px-2 py-1 rounded-md bottom-2">
                        </div>
                      </div>
                    </a>
                    {/* See Before/After Button */}
                    <div className="absolute z-30 left-1/2 transform -translate-x-1/2 mt-4 bottom-4">
                      <button
                        className="text-gray-600 bg-white px-3 py-2 rounded-md shadow-sm hover:text-black transition duration-300 opacity-80 text-xs group w-44"
                        onClick={() => setShowBefore(prevState => !prevState)}
                        onMouseEnter={() => !isMobile && setShowBefore(true)}
                        onMouseLeave={() => !isMobile && setShowBefore(false)}
                        >
                        {showBefore
                          ? "See the after photo"
                          : "See the before photo"}
                      </button>
                    </div>
                  </div>

                  
                  

                  {/* New Third Image (Placed to the right of the Before Image) */}
                  <div className="absolute md:top-28 md:right-36 right-48 md:w-9/12 w-10/12 translate-x-44 -translate-y-10 z-20 opacity-85 top-20">
                            <div className="relative">
                              <Image
                                className="h-auto w-screen transform ease-out hover:translate-y-3 duration-1000 transition md:w-11/12"
                                src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/add-prompt.gif"
                                alt="Third Image"
                                width={2000}
                                height={2000}
                              />
                            </div>
                          </div>
                </div>
                
              </div>
              <div className="flex flex-row items-start justify-center space-x-6" />
               {/* H1 Headline Section */}
                   <div className="relative z-10 container mx-auto px-4">
                        <div className="flex flex-wrap justify-center items-center mx-auto">
                          <div className="w-auto pt-10">
                            <h1 className="font-heading text-center md:text-5xl xl:text-10xl max-w-5xl mx-auto md:text-9xl text-3xl sm:text-7xl">Quick &amp; Simple Interior Design with MyRoomDesigner.AI</h1>
                          </div>
                        </div>
                      </div>

                  {/* Feature List Section */}
                  <div className="relative z-10 container mx-auto px-4 pt-6">
                        <ul className="flex flex-row items-start sm:flex-row text-black space-x-6 text-center justify-center flex-wrap pb-6">
                          <li className="flex items-center mb-2">
                            <div className="rounded-full p-1 flex justify-center items-center bg-green-100">
                              <svg className="text-black w-3 h-3" fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                            <p className="font-heading text-black text-base ml-2">Furnished or Unfurnished</p>
                          </li>
                          <li className="flex items-center mb-2">
                            <div className="rounded-full p-1 flex justify-center items-center bg-green-100">
                              <svg className="text-black w-3 h-3" fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                            <p className="font-heading text-black text-base ml-2">Customizable Prompts</p>
                          </li>
                          <li className="flex items-center mb-2">
                            <div className="rounded-full p-1 flex justify-center items-center bg-green-100">
                              <svg className="text-black w-3 h-3" fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                            <p className="font-heading text-black text-base ml-2">Accurate AI Photo Description</p>
                          </li>
                          <li className="flex items-center mb-2 hide hidden lg:flex">
                            <div className="rounded-full p-1 flex justify-center items-center bg-green-100">
                              <svg className="text-black w-3 h-3" fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                            <p className="font-heading text-black text-base ml-2">High-Resolution Upscale</p>
                          </li>
                          <li className="flex items-center mb-2 hidden lg:flex">
                            <div className="rounded-full p-1 flex justify-center items-center bg-green-100">
                              <svg className="text-black w-3 h-3" fill="none" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.95831 10.2917L7.12498 13.4584L15.0416 5.54169" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </div>
                            <p className="font-heading text-black text-base ml-2">Within 60 Seconds</p>
                          </li>
                        </ul>
                        
                      </div>
              {/* Buttons (centered below features) */}
              <div className="flex space-x-4 justify-center items-center pb-6">
                <button
                  className="group relative flex items-center justify-center font-heading block py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2 hover-ring-blue-300 bg-blue-50 bg-gradient-to-r from-indigo-400 text-white to-indigo-300"
                  onClick={() => {setShowHeaderSection(false);window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                  });}}
                >
                  <p className="relative">Design your room now</p>
                </button>
                {/* New minimalistic button */}
                <a
                  className="flex items-center space-x-2 text-gray-900 font-heading text-xs transition hover:text-gray-700"
                  href="#upload-and-redesign"
                >
                  <span>Learn how it works</span>
                  <svg
                    className="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
              
            </div>
          </div>
        </div>
      </section>

  <section className="mx-auto bg-[#f7f6f4] bs-section-dragged px-8 py-36 lg:py-44 lg:p-44">
    <div className="container mx-auto">
  <h2 id="upload-and-redesign" className="font-heading sm:text-7xl md:text-9xl xl:text-10xl mb-24 text-4xl text-center md:text-left">How My Room Designer works</h2>
  {/* Step 1 */}
  <div className="flex flex-col md:flex-row items-center mb-20 xl:mb-6">
    <div className="w-full md:w-1/12 mb-4 md:mb-0">
      <p className="font-heading text-4xl text-center md:text-left">01</p>
    </div>
    <div className="w-full md:w-5/12 mb-4 md:mb-0 text-center md:text-left">
      <h2 className="mb-4 font-heading text-xl md:text-3xl md:font-bold font-bold text-center md:text-left">Upload Your Room Photo</h2>
      <Image
        className="rounded-lg w-full object-cover md:mb-0 md:mr-4 transform ease-out hover:translate-y-3 duration-1000 transition md:hidden mb-6"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step1-My-Room-Designer-AI.png"
        alt="Interior Design Inspiration"
        width={2000}
        height={2000}
      />
      <p className="w-10/12 font-heading mb-6 text-gray-600 text-center md:text-left mx-auto md:-mx-0">Start by choosing either "Inspiration" for fresh ideas or "Restyling" to reimagine your actual room. Select your room category, and then sit back as the AI analyzes and describes your photo, laying the perfect foundation for your design journey.</p>
      <a href="/interior-design-ai/designer" className="group relative inline-flex font-heading py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2  ring-2 ring-gray-700 hover:ring-gray-500 ">
        <p className>Go to designer</p>
      </a>
    </div>
    <div className="hidden md:block w-full md:w-6/12 lg:pl-8 lg:pl-10">
      <Image
        className="rounded-lg w-full object-cover rounded-3xl transform ease-out hover:translate-y-3 duration-1000 transition"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step1-My-Room-Designer-AI.png"
        alt="Interior Design Inspiration"
        width={2000}
        height={2000}
      />
    </div>
  </div>
  {/* Step 2 */}
  <div className="flex flex-col md:flex-row items-center mb-20 xl:mb-28 text-center md:text-left">
    <div className="w-full md:w-1/12 mb-4 md:mb-0">
      <p className="font-heading text-4xl ">02</p>
    </div>
    <div className="w-full md:w-5/12 mb-4 md:mb-0">
      <h2 className="mb-4 font-heading text-xl md:text-3xl md:font-bold font-bold text-center md:text-left">Add Your Preferences</h2>
      <Image
        className="rounded-lg w-full object-cover shadow-xl md:mb-0 md:mr-4 rounded-xl transform ease-out hover:translate-y-3 duration-1000 transition md:hidden mb-6"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/add-prompt.gif"
        alt="Collaboration with Designer"
        width={2000}
        height={2000}
      />
      <p className="text-gray-600 w-10/12 font-heading mb-6 text-center md:text-left mx-auto md:mx-0">Select your preferred style and add details to customize. Feel free to chat with the AI to swap furniture items, adjust colors, or modify any aspect you desire. Easily personalize your prompt by clicking the 'Change Prompt' button or manually rewriting it for a more tailored touch.</p>
      <a href="/interior-design-ai/designer" className="group relative inline-flex font-heading  py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2  ring-2 ring-gray-700 hover:ring-gray-500 ">
        <p className>Go to designer</p>
      </a>
    </div>
    <div className="hidden md:block md:w-6/12 lg:pl-8">
      <Image
        className="rounded-lg w-full object-cover shadow-xl rounded-xl transform ease-out hover:translate-y-3 duration-1000 transition"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/add-prompt.gif"
        alt="Collaboration with Designer"
        width={2000}
        height={2000}
      />
    </div>
  </div>
  {/* Step 3 */}
  <div className="flex flex-col md:flex-row items-center text-center md:text-left">
    <div className="w-full md:w-1/12 mb-4 md:mb-0">
      <p className="font-heading text-4xl text-center md:text-left">03</p>
    </div>
    <div className="w-full md:w-5/12 mb-4 md:mb-0">
      <h2 className="mb-4 font-heading text-xl md:text-3xl md:font-bold font-bold text-center md:text-left">Receive Your Design</h2>
      <Image
        className="rounded-lg w-full object-cover shadow-xl md:mb-0 md:mr-4 rounded-3xl transform ease-out hover:translate-y-3 duration-1000 transition md:hidden mb-6"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step3-Create-Similar.png"
        alt="Final Interior Design"
        width={2000}
        height={2000}
      />
      <p className="text-gray-600 w-10/12 font-heading mb-6 text-center md:text-left mx-auto md:mx-0">Instantly receive three personalized design options, and with just one click, generate similar alternatives to explore even more possibilities. You can upscale your photo for high-resolution results, remove unwanted items, find similar products based on what’s in your photo, or create a shopping list in our editor.</p>
      <a href="/interior-design-ai/designer" className="group relative inline-flex py-2 px-5 text-sm sm:text-base rounded-full cursor-pointer font-heading hover:ring-2  ring-2 ring-gray-700 hover:ring-gray-500 ">
        <p className>Start your room makeover</p>
      </a>
    </div>
    <div className="hidden md:block w-full md:w-6/12 lg:pl-8">
      <Image
        className="rounded-lg w-full object-cover shadow-xl transform ease-out hover:translate-y-3 duration-1000 transition rounded-xl"
        src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/Step3-Create-Similar.png"
        alt="Final Interior Design"
        width={2000}
        height={2000}
      />
    </div>
  </div>
  </div>
</section>




      <section className="py-44 overflow-hidden">
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap items-center -m-6">
      <div className="w-full md:w-1/2 p-6">
        <div className="max-w-lg mx-auto mb-28 md:mb-0">
          {/* Phone layout: Title and description above the photo */}
          <p className="mb-9 text-center text-transparent bg-clip-text bg-gradient-cyan font-heading text-xs font-semibold tracking-px md:hidden">Interior Design by MyRoomDesigner.AI</p>
          <h2 className="mb-7 font-heading text-center text-5xl sm:text-7xl md:text-9xl xl:text-10xl md:hidden">Transform Your {isStartpage ? 'Space' : roomName} with My Room Designer</h2>
          <p className="text-base text-center mx-auto md:w-2/3 text-gray-600 mb-9 md:hidden">Experience effortless room design like never before. Whether you aim to revamp your living room, create a serene bedroom retreat, or establish an efficient workspace, My Room Designer delivers a variety of options that reflect your unique style and vision, making the design process both enjoyable and inspiring.</p>
        </div>
        <div className="flex flex-wrap justify-center -m-6">
          <div className="w-full lg:w-auto p-6">
            <div className="max-w-max mx-auto aspect-square">
                <div className="mb-5">
                <Image
                    src="https://storage.googleapis.com/moodly-16ff8.appspot.com/ai-generator/upscaled_images/f79evm5pvdrj20cjq5r9rzne0c.jpg"
                    alt="Room Design by MyRoomDesigner.AI"
                    width={2000}
                    height={2000}
                    className="aspect-square object-cover w-full"
                    sizes="100vw"
                    priority={false}
                  />
              </div>
              <h3 className="mb-1 font-heading font-bold text-xl text-gray-900">Living Room Design by MyRoomDesigner.AI</h3>
              <p className="text-xs text-gray-600">Added to the prompt: Magnolia Style Living Room</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-6 hidden md:block">
        <div className="max-w-lg mx-auto">
          <p className="mb-9 text-center text-transparent bg-clip-text bg-gradient-cyan font-heading text-xs font-semibold tracking-px">Interior Design by MyRoomDesigner.AI</p>
          <h2 className="mb-7 font-heading text-center text-gray-900 text-5xl sm:text-7xl md:text-9xl xl:text-10xl">Transform Your {isStartpage ? 'Space' : roomName} with My Room Designer</h2>
          <p className="text-base text-center mx-auto md:w-2/3 text-gray-600">
            <span style={{fontFamily: 'Lustria, serif', fontSize: 'medium'}}>Experience effortless room design like never before. Whether you're revamping your living room, creating a tranquil bedroom, or designing a functional workspace, our platform offers diverse options that reflect your unique style, making the design process enjoyable and inspiring.</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<section className="overflow-hidden">
  <div className="container mx-auto px-4">
    <div className="flex flex-wrap items-center -m-6 pb-44">
      <div className="w-full md:w-1/2 p-6">
        <div className="max-w-lg mx-auto">
          <p className="mb-9 text-center text-transparent bg-clip-text bg-gradient-cyan font-heading text-xs font-semibold tracking-px">Interior Design by MyRoomDesigner.AI</p>
          <h2 className="mb-7 font-heading text-center text-5xl sm:text-7xl md:text-9xl xl:text-10xl">Integrate Top Design Styles</h2>
          <p className="text-base text-center mx-auto md:w-2/3 text-gray-600 mb-28">
            <span style={{fontFamily: 'Lustria, serif', fontSize: 'medium'}}>Love Magnolia, Becki Ovens, Studio McGee, or Shoppe Interiors? Now you can easily incorporate popular brand styles into your design. MyRoomDesigner.AI allows you to select your preferred aesthetic and create a room that feels like it’s straight out of your favorite design magazines.</span>
          </p>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-6">
        <div className="flex flex-wrap justify-center -m-6">
          <div className="w-full lg:w-auto p-6">
            <div className="max-w-max mx-auto">
              <div className="mb-5">
                <Image
                  src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/MyRoomDesignerAI-result2.jpg"
                  alt="Living Room Design"
                  width={2000}
                  height={2000}
                  className="aspect-square object-cover"
                  sizes="100vw"
                />
              </div>
              <h3 className="mb-1 font-heading font-bold text-xl text-gray-900">Warm and Cozy Living Room Styles by MyRoomDesigner.AI</h3>
              <p className="text-xs text-gray-600">Added to the prompt: Magnolia Style</p>
            </div>
          </div>
          <div className="w-full lg:w-auto p-6">
            <div className="xl:mt-24 max-w-max mx-auto">
              <div className="mb-5">
                <Image
                  src="https://static.shuffle.dev/uploads/files/68/685cdf6531559900d35917105bd95b994e60c466/MyRoomDesignerAI-result1.jpg"
                  alt="Living Room Design"
                  width={2000}
                  height={2000}
                  className="aspect-square object-cover"
                  sizes="100vw"
                />
              </div>
              <h3 className="mb-1 font-heading font-bold text-xl text-gray-900" />
              <h3 className="mb-1 font-heading font-bold text-xl text-gray-900" >Unique Living Room Designs by MyRoomDesigner.AI</h3>
              <p className="text-xs text-gray-600">Added to the prompt: Studio McGee Style</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ThreeInOne />

<TestimonialSlider />

<StorySection />

      <FAQ
        faqs={[
          {
            question: `How does the Interior Design AI create ${style} ${roomName.toLowerCase()} designs?`,
            answer: `Our Interior Design AI uses advanced machine learning algorithms to analyze your uploaded ${roomName.toLowerCase()} photos or sketches. It identifies key elements such as layout, furniture placement, and color schemes. Based on the input, it generates multiple ${style} design options tailored to your preferences. You can refine the results by adjusting details like room style, color palette, and furniture preferences. The AI leverages design principles to ensure your ${roomName.toLowerCase()} is both aesthetically pleasing and functional.`,
          },
          {
            question: `Can I design various styles besides ${style} for my ${roomName.toLowerCase()}?`,
            answer: `Yes, you can! While we excel at creating ${style} designs, MyRoomDesigner.AI offers many other styles including Modern, Rustic, Industrial, and more. You can also incorporate top design styles like Love Magnolia, Becki Owens, Studio McGee, or Shoppe Interiors. Simply describe the changes you'd like to make to your ${roomName.toLowerCase()}, click "Change Prompt," and the tool will adjust the description to reflect your desired style. This allows you to create a room that matches your personal vision with ease.`,
          },
          {
            question: `How closely will the AI designs match my current ${roomName.toLowerCase()}?`,
            answer: `Our AI accurately scans and analyzes your ${roomName.toLowerCase()}'s layout and materials from your photos. It then generates ${style} designs that closely match the style and structure of your existing space. You can further refine and adjust the design by manually editing the description or using the "Change prompt" button in the chat option to add specific details, such as "add black curtain rod and beige linen curtains" or "swap the furniture." This allows you to customize the ${roomName.toLowerCase()} design to perfectly match your vision.`,
          },
          {
            question: `Can I find the items from the generated ${style} ${roomName.toLowerCase()} design?`,
            answer: `Yes, you can! Simply click on the "Find similar products" button, and by selecting the items within your ${roomName.toLowerCase()} photo, you'll be able to view similar real-life products that match the ${style} style.`,
          },
          {
            question: `How customizable are the ${style} designs generated by MyRoomDesigner.AI?`,
            answer: `The ${style} designs generated for your ${roomName.toLowerCase()} are highly customizable! You can adjust colors, swap furniture, and tweak layouts to your preference. Our tool also offers several advanced features such as upscaling your favorite image to high resolution, cleaning up photos, removing unwanted items, and creating similar designs. Additionally, you can add your ${roomName.toLowerCase()} design to the Mood Board Designer as a reference photo, build a mood board, and even generate a shopping list based on the design!`,
          },
          {
            question: `How can interior designers use this tool for their ${roomName.toLowerCase()} projects?`,
            answer: `With MyRoomDesigner.AI, MoodBoard AI, and Board Editor, interior designers can easily create multiple ${style} ${roomName.toLowerCase()} concepts, mood boards, shopping lists, and color palettes. The tool also allows for the creation of client-ready presentations, helping designers enhance their creative process, streamline their workflow, and present polished, professional ${roomName.toLowerCase()} designs to clients.`,
          },
          {
            question: "How do I cancel my subscription?",
            answer: "Manage your subscription through your dashboard. Cancellation will take effect at the end of your billing period.",
          },
          {
            question: "Can I change my subscription plan for MyRoomDesigner.AI?",
            answer: `Yes, you can upgrade or downgrade anytime via the Billing section in your account. This gives you flexibility in how many ${style} ${roomName.toLowerCase()} designs you can create each month.`,
          },
          {
            question: "Is my data secure when using MyRoomDesigner.AI?",
            answer: `Your ${roomName.toLowerCase()} photos and designs are encrypted and private. We don't share data with third parties, and you can delete it anytime via account settings.`,
          },
          {
            question: "Can I get a refund for the AI room design service?",
            answer: `Refunds aren't offered due to the immediate GPU resource consumption required for generating ${style} ${roomName.toLowerCase()} designs. However, we recommend starting with our monthly-paid Personal subscription, which you can cancel at any time. This way, you can try the service risk-free and decide if it fits your needs.`,
          },
        ]}
      />
    </>
  )
}

export default MarketingContent